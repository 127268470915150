<template>
  <section class="main-section shoutouts">
    <div class="filters">
      <label>Filter by</label>
      <ul>
        <li v-if="me.is_admin">
          <user-groups-filter
            v-model="filters.user_groups"
            :label="filter_label('groups')"
          />
        </li>
        <li>
          <user-segment-filter
            v-model="filters.user_segments"
            :label="filter_label('segments')"
          />
        </li>
        <li>
          <awards-filter
            v-model="filters.awards"
            :label="filter_label('awards')"
          />
        </li>
        <li>
          <user-segment-filter
            v-model="filters.sent_by_segments"
            :label="filter_label('sent_by')"
          />
        </li>
        <li>
          <user-segment-filter
            v-model="filters.received_by_segments"
            :label="filter_label('received_by')"
          />
        </li>
      </ul>
    </div>

    <div class="content">
      <div class="table-wrap">
        <template v-if="activity !== null">
          <ht-sortable-table
            v-if="activity.length > 0"
            :rows="activity"
            :columns="[
              'Date',
              'Sent by',
              'Recipient',
              'Award earned',
              'Points earned',
              'Message',
            ]"
            :sortable="{
              '1': 'created_at',
              '2': 'created_by.name',
              '3': 'for_user.name',
              '4': 'award.name',
              '5': 'points',
            }"
          >
            <template #rows="{rows, sort_by}">
              <tr
                v-for="(row, index) in rows"
                :key="`activity_row_${sort_by}_${index}`"
              >
                <td>
                  {{ $formatDate(row.created_at) }}
                </td>
                <td>
                  <user-card
                    v-if="row.created_by"
                    :user="row.created_by"
                    :height="22"
                  />
                </td>
                <td>
                  <user-card :user="row.for_user" :height="22" />
                </td>
                <td>{{ row.award.name }}</td>
                <td>
                  {{ $formatPoints(row.points) }}
                </td>
                <td>{{ row.message }}</td>
              </tr>
            </template>
          </ht-sortable-table>
          <div class="empty-state" v-else>
            No activity found with these filters.
          </div>
        </template>
        <loading-indicator v-else />
      </div>
    </div>
  </section>
</template>

<script>
import { debounce } from 'lodash';

import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups';
import UserSegmentFilter from '@/components/Reporting/Filters/UserSegments';
import ValuesFilter from '@/components/Reporting/Filters/Values';
import AwardsFilter from '@/components/Reporting/Filters/Awards';

export default {
  props: ['dateRange'],
  components: {
    UserGroupsFilter,
    UserSegmentFilter,
    ValuesFilter,
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      activity: null,
      filters: {
        user_groups: [],
        user_segments: [],
        sent_by_segments: [],
        received_by_segments: [],
        values: [],
        awards: [],
        date_range: this.dateRange,
      },
    };
  },
  watch: {
    dateRange(val) {
      this.filters.date_range = val;
    },
    filters: {
      deep: true,
      handler: function() {
        this.populateActivity();
      },
    },
  },
  beforeDestroy() {
    this.$parent.$off('exportTable', this.export);
  },
  created() {
    if (this.$route.query.group)
      this.filters.user_groups = [this.$route.query.group.toString()];
    if (this.$route.query.award_id)
      this.filters.awards = [this.$route.query.award_id];

    this.populateActivity();

    this.$parent.$on('exportTable', this.export);
  },
  methods: {
    populateActivity: debounce(async function() {
      this.activity = null;
      const resp = await this.$api.Reporting.get_awards(this.filters, true);
      this.activity = resp;
    }, 250),
    filter_label(filter) {
      switch (filter) {
        case 'segments':
          return this.filters.user_segments.length > 0
            ? `Segments (${this.filters.user_segments.length})`
            : 'Segments';
        case 'sent_by':
          return this.filters.sent_by_segments.length > 0
            ? `Sent by (${this.filters.sent_by_segments.length})`
            : 'Sent by';
        case 'received_by':
          return this.filters.received_by_segments.length > 0
            ? `Received by (${this.filters.received_by_segments.length})`
            : 'Received by';
        case 'groups':
          return this.filters.user_groups.length > 0
            ? `Channels (${this.filters.user_groups.length})`
            : 'Channels';
        case 'values':
          return this.filters.values.length > 0
            ? `Values (${this.filters.values.length})`
            : 'Values';
        case 'awards':
          return this.filters.awards.length > 0
            ? `Awards (${this.filters.awards.length})`
            : 'Awards';
      }
    },
    export() {
      const custom_fields = Object.entries(this.activity[0]).reduce(
        (agg, [key, val]) => {
          if (key.substr(0, 3) === 'CF_') agg.push(key.substr(3));
          return agg;
        },
        []
      );

      // const recipientColumns = [...Array(this.activity.reduce((total, row) => ( (row.for_users.length > total ? row.for_users.length : total) ), 0)).keys()]
      //   .map((col) => `Recipient ${(col+1)}`);

      window.generateCSV('HiThrive Awards by Recipient Report.csv', [
        [
          'Date',
          'Award name',
          'Award type',
          'Sent by (Name)',
          'Sent by (Email)',
          'Sender Manager (Name)',
          'Sender Manager (Email)',
          'Recipient (Name)',
          'Recipient (Email)',
          'Recipient Manager (Name)',
          'Recipient Manager (Email)',
          'Points received',
          'Reaction count',
          'Message',
          ...custom_fields,
        ],
        ..._.map(this.activity, (r) => [
          this.$csvDateFormat(r.created_at),
          r.award.name,
          r.award.is_bot ? r.award.bot_type : 'P2P',
          r.created_by ? r.created_by.name : 'Bot award',
          r.created_by ? r.created_by.email : '',
          r.created_by?.reports_to ? r.created_by.reports_to.name : '',
          r.created_by?.reports_to ? r.created_by.reports_to.email : '',
          r.for_user.name,
          r.for_user.email,
          r.for_user?.reports_to ? r.for_user.reports_to.name : '',
          r.for_user?.reports_to ? r.for_user.reports_to.email : '',
          r.points,
          Array.isArray(r.reactions)
            ? r.reactions.reduce((total, reaction) => {
                total += reaction.count;
                return total;
              }, 0)
            : 0,
          r.message,
          ...Object.entries(r).reduce((agg, [k, v]) => {
            if (k.substr(0, 3) === 'CF_') agg.push(v);
            return agg;
          }, []),
        ]),
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 25px 0 0;
}
.table-wrap {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $light;

  ::v-deep > table {
    font-size: 14px;
    margin: 0;

    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>
